import p1 from './pages/index';
import p2 from './pages/contact';

$(document).ready(() => {
    // get the current page we are on. every page in our app must have this value
    const currentPage = $('[data-js="the-current-page"]').data('current-page');

    switch (currentPage) {
        case 'p1': {
            p1();
            break;
        }
        case 'p2': {
            p2();
            break;
        }
        default: {
            // do nothing
        }
    }
});
