import Tippy from 'tippy.js';
import Clipboard from 'clipboard';

let elTippyReference;
let elTippyTemplate;

function publicInit() {
    _initVars();

    _initListeners();
}

function _initVars() {
    elTippyReference = '[data-js="tippy-reference-element2"]';
    elTippyTemplate = '[data-js="tippy-template"]';
}

function _initListeners() {
    const tippyEl = document.querySelector(elTippyReference);
    const tippyTemplate = document.querySelector(elTippyTemplate);
    Tippy(tippyEl, {
        html: tippyTemplate,
        trigger: 'manual',
        hideOnClick: false,
        animation: 'scale',
        arrow: true,
        arrowType: 'round',
        duration: 500,
        theme: 'white',
        interactive: true,
        interactiveBorder: 40,
        placement: 'bottom',
    });

    const clipbrd = new Clipboard(elTippyReference);
    // don't highlight/select text on copy
    clipbrd.on('success', (e) => {
        e.clearSelection();
    });

    // listen for clicks
    $(elTippyReference).on('click', () => {
        tippyEl._tippy.show();

        window.setTimeout(
            () => {
                tippyEl._tippy.hide();
            },
            2000,
        );
    });
}

export default publicInit;
