import Tippy from 'tippy.js';
import Clipboard from 'clipboard';

let elTippyReference;
let elTippyTemplate;
let elCopyButton;

function publicInit() {
    _initVars();

    _initListeners();
}

function _initVars() {
    elTippyReference = '[data-js="tippy-reference-element"]';
    elTippyTemplate = '[data-js="tippy-template"]';
    elCopyButton = '[data-js="copy-email"]';
}

function _initListeners() {
    const tippyEl = document.querySelector(elTippyReference);
    const tippyTemplate = document.querySelector(elTippyTemplate);
    Tippy(tippyEl, {
        html: tippyTemplate,
        animation: 'scale',
        duration: 500,
        theme: 'white',
        interactive: true,
        interactiveBorder: 40,
        placement: 'bottom',
    });

    const clipbrd = new Clipboard(elCopyButton);
    // don't highlight/select text on copy
    clipbrd.on('success', (e) => {
        e.clearSelection();
    });
}

export default publicInit;
